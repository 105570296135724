.create-clips {
  border: 6px solid black;
  border-radius: 6px;
  width: 322px;
  background-color: #DCF2FF;
}

.create-clips:hover {
  background-color: #00A3FF;
}

.video {
  height: 310px;
  width: 310px;
  object-fit: cover;
  margin-bottom: 0px;
}

a > video:only-child {
  display: block;
}
